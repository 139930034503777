import React from "react"
import Layout from "../components/layout"
import InfoSection from "../components/InfoSection"

const About: React.FC = () => (
  <Layout>
    <InfoSection title="O nas">
      <p>Jesteśmy wyłącznym dystrybutorem firmy Kuwotech w Polsce.</p>
      <p>Zajmujemy się sprzedażą koreańskich implantów najwyższej jakości.</p>
      <p>
        Zapewniamy dożywotnią gwarancje na implant, wygodę lekarza w pracy i
        zadowolenie klienta. Nasz profesjonalny i doświadczony zespół w
        dziedzinie implantologii z miłą chęcią odpowie na każde Państwa pytanie.
      </p>
    </InfoSection>
  </Layout>
)

export default About
